import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import React, { Component }  from 'react';
const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const refForm = useRef
  const form = useRef();
  // useEffect(() => {
  //   return setTimeout(() => {
  //     setLetterClass('text-animate-hover')
  //   }, 3000)
  // }, [])

  useEffect(() => {
    const animation = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000);
    return ()=> clearTimeout(animation);
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      //need the public key and all other details here
      .sendForm('service_d3ufduh', 'template_wr0j2lq', form.current, 'b1_Zvw2uWj1S1TqYS')
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            Please feel free to drop me a message.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          Deshan Jayampath
          <br />
          Canada
          <br />
         Saskatoon,SK and Milton,Ontario <br />
          Canada <br />
          <br />
          <span className='emailaddress'><a href="mailto:m.deshanJ@gmail.com"> m.deshanj@gmail.com</a></span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[52.128546, -106.637129]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[52.128546, -106.637129]}>
              <Popup>Let's have a chat :)</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Contact
