
import './App.scss';
import {Routes,Route,HashRouter,HashHistory} from 'react-router-dom'
import { browserHistory, Router, IndexRoute } from 'react-router'
import Layout from './components/Layout'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Portfolio from './components/Portfolio';
import Resume from './components/Resume'
import Sublayout from './components/Sub_layout'
function App() { 
  return (

    <>
    <Routes history={HashRouter}>
      <Route path="/" element={<Layout/>}>
      <Route index element={<Home/>}/>
      </Route>
      <Route path="/about" element={<Sublayout/>}>
      <Route index element={<About/>}/>
      </Route>
      <Route path="/contact" element={<Sublayout/>}>
      <Route index element={<Contact/>}/>
      </Route>
      <Route path="/portfolio" element={<Sublayout/>}>
      <Route index element={<Portfolio/>}/>
      </Route>
      <Route path="/resume" element={<Sublayout/>}>
      <Route index element={<Resume/>}/>
      </Route>
    </Routes>
    
    </>
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >


  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  );
}

export default App;
