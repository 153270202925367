import { useEffect, useState } from 'react'
import {
  faAngular,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
  faPython,
  faYoutube,
  faJava,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  
  const [letterClass,setLetterClass] = useState('text-animate')
  useEffect(() => {
    const animation = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000);
    return ()=> clearTimeout(animation);
  }, [])


  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
          Currently I am working as a student researcher for DICE in Saskatchewan Polytechnic for a machine learning project. We are using Python and machine learning models in Linux, windows, and WSL environments for this project. Project progress is being recorded with Kanban boards, weekly team meetings and customer meetings. In the previous project, a web app for grant management was developed using React JS, MongoDB(database), Cypress(unit testing), Microsoft planner, pair programming, Git and agile methodologies.  
          </p>
          <p align="LEFT">
          I finished Computer Systems Technology diploma at Saskatchewan Polytechnic in June 2022. From Computer Systems Technology diploma I gained the confidence in using Java, C, C#, JavaScript, Html, CSS, Vue JS, React JS, Rust, Xamarin Forms, jQuery, Typescript, MongoDB, C, SQL, GIT and UNIX/Linux systems. 
          </p>
          <p>
          Previously I worked as a Business System Analyst for 1.5 years where I investigated customer issues in detail and provided support, training, solutions, and recommendations for IFS Enterprise Resource Planning (ERP) Applications. As a subject matter expert in IFS, I communicated with variety of customers from different industries all over the world to provide urgent technical support for critical issues reported through emails, voice calls, skype meetings and WebEx meetings. Additionally, I planned and coordinated Delivery, MS Patching, Clone activities, change & service requests, root cause analysis and system access requests for the managed services customers in a timely manner with managed service partners.
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faPython} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJava} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faYoutube} color="#EC4D28" />
            </div>
          </div>
        </div>
      
      </div>
      <Loader type="pacman"/>
    </>
  )
}

export default About