import { useEffect, useState } from 'react'

import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import jobData from '../../data/jobs.json'
import eduData from '../../data/education.json'
const Resume = () => { 
    const [letterClass, setLetterClass] = useState('text-animate');
    const [portfolio, setPortfolio] = useState([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });
    const listItems = (lt)=>{
        
        
        return(
            <ul>
                {
                lt.split(";").map((listpoint,i)=>{
                    return <li key={i}>{listpoint}</li>
                }
                
                )
                }

            </ul>
        )
    }

    const renderJobs = (job) => {
        return (
            <div className="job-container">
                {
                    job.map((port, idx) => {
                        return (
                            <div className="image-box" key={idx}>
                               
                                <div className="content">
                                    <h3 className="position">{port.position}</h3>
                                    <p className="compnay">{port.company}</p>
                                    <p className="location">{port.location}</p>
                                    <p className="time">{port.time}</p>
                                    <div className="work">{listItems(port.work)}</div>                                  
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
    const renderEdu = (edu) => {
        return (
            <div className="edu-container">
                {
                    edu.map((port, idx) => {
                        return (
                            <div className="edu-box" key={idx}>
                               
                                <div className="content">
                                    <h3 className="school">{port.school}</h3>
                                    <p className="course">{port.course}</p>
                                    <p className="location">{port.location}</p>
                                    <p className="duration">{(port.duration)}</p>
                                                                    
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    return (
        <>
            <div className="resume-page">
            
            <div className="text-zone">
                <h1> 
                <AnimatedLetters
              letterClass={letterClass}
              strArray={['E', 'x', 'p', 'e', 'r', 'i', 'e', 'n','c','e']}
              idx={15}
            />
            <div className='parentjobcontainer'>{renderJobs(jobData.jobs)}</div>
            
            </h1>
                <h1> 
                <AnimatedLetters
              letterClass={letterClass}
              strArray={['E', 'd', 'u', 'c', 'a', 't', 'i', 'o','n']}
              idx={15}
            /><div className='parenteducontainer'>{renderEdu(eduData.education)}</div>

                </h1>
                
                </div>
            </div>
            
            <Loader type="pacman"/>
        </>
    )

}

export default Resume