import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import LogoTitle from '../../assets/images/logo-J.png'
import Logo from './Logo'
import './index.scss'
import React, { Component }  from 'react';
const Home = () =>{
    const [letterClass,setLetterClass] = useState('text-animate')
    const nameArray= ['a','y','a','m','p','a','t','h']
    const nameArray1= ['D','e','s','h','a','n',' ']
    const jobArray =['S','o','f','t','w','a','r','e','  ','D','e','v','e','l','o','p','e','r']

    useEffect(()=> {
        // return setTimeout(()=> {
        //     setLetterClass('text-animate-hover')
        // },1);
        const pattern = setTimeout(()=> {
                setLetterClass('text-animate-hover')
            },4000);
        
        // pattern();
        return ()=>clearTimeout(pattern);

    },[]);

    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass}_12`}>e</span>
                    <span className={`${letterClass}_13`}>y,</span>
                    <br/>
                    <span className={`${letterClass}_12`}>I</span>
                    <span className={`${letterClass}_13`}>'m  </span>

                    <AnimatedLetters letterClass={letterClass} strArray={nameArray1} idx={15}/>
                    
                    <img src={LogoTitle} alt="developer"/>
                    <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                    
                    <br/>
                    <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={15}/>
                </h1>
                <h2>Java/Python/C++/C# deveoper/Frontend Developer/ Chemical Engineer/ Content Creator</h2>
                <Link to='/contact' className='flat-button'>Conact Me</Link>

            </div>
            <Logo />
        </div>
        <Loader type="pacman"/>
        </>
    );
}
export default Home