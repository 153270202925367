import './index.scss'
import LogoS from '../../assets/images/logo-DJ.png'
import LogoSubtitle from '../../assets/images/logo-DJ_full.png'
import {Link, NavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome,faUser,faEnvelope, faBriefcase, faUsersRectangle, faPersonArrowUpFromLine, faFileText } from '@fortawesome/free-solid-svg-icons'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

// this will be the side bar in the page
const Sidebar=() => (
        <div className='nav-bar'>
            <Link className='logo' to='/'>
                <img className='main-logo' src={LogoS} alt="logo" />
                <img className="sub-logo "src={LogoSubtitle} alt="slobodan" />
            </Link>
        
        <nav>
            <NavLink exact="true" activeclassname="active" to="/">
                <FontAwesomeIcon icon={faHome} color="#4d4d4e"/>

            </NavLink>
            <NavLink exact="true" activeclassname="active" className="about-link" to="/about">
                <FontAwesomeIcon icon={faUser} color="#4d4d4e"/>

            </NavLink>
           
            <NavLink exact="true" activeclassname="active" className="portfolio-link" to="/portfolio">
                <FontAwesomeIcon icon={faBriefcase} color="#4d4d4e"/>

            </NavLink>
            <NavLink exact="true" activeclassname="active" className="resume-link" to="/resume">
                <FontAwesomeIcon icon={faFileText} color="#4d4d4e"/>

            </NavLink>
            <NavLink exact="true" activeclassname="active" className="contact-link" to="/contact">
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e"/>

            </NavLink>
        </nav>
            <ul>
                <li>
                    <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/in/deshanjayampath/'>
                        <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e"></FontAwesomeIcon>
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="noreferrer" href='https://github.com/DeshanJayampath'>
                        <FontAwesomeIcon icon={faGithub} color="#4d4d4e"></FontAwesomeIcon>
                    </a>
                </li>
            </ul>
        </div>
        
    )

export default Sidebar

